<template>
  <default-layout>
    <v-container id="user-profile" fluid tag="section">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <!--          <profile-form :user="user"></profile-form>-->
          <change-pass-form :user="user"></change-pass-form>
        </v-col>

        <v-col cols="12" md="4">
          <base-material-card class="v-card-profile" avatar="/img/placeholder.png">
            <v-card-text class="text-center">
              <h6 class="text-h4 mb-1 grey--text">
                {{ user.email }}
              </h6>

              <h4 class="text-h3 font-weight-light mb-3 black--text">
                {{ user.name }}
              </h4>

              <!--              <p class="font-weight-light grey&#45;&#45;text">-->
              <!--                Don't be scared of the truth because we need to restart the human foundation in-->
              <!--                truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the-->
              <!--                back is...-->
              <!--              </p>-->

              <!--              <v-btn color="success" rounded class="mr-0">-->
              <!--                Follow-->
              <!--              </v-btn>-->

              <v-btn color="success" class="mr-0" @click="logout">
                {{ $t('profile.logout') }}
              </v-btn>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
      <!--      <v-row justify="center">-->
      <!--        <v-col cols="12" md="8">-->
      <!--          <change-pass-form :user="user"></change-pass-form>-->
      <!--        </v-col>-->

      <!--        <v-col cols="12" md="4"></v-col>-->
      <!--      </v-row>-->
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
// const ProfileForm = () => import('@/components/profile/profile-form');
const ChangePassForm = () => import('@/components/profile/change-pass-form');
import { mapState } from 'vuex';
export default {
  components: {
    // ProfileForm,
    ChangePassForm,
    DefaultLayout,
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
  },
  methods: {
    async logout() {
      await this.$store.dispatch('global/logout');
      this.$router.go(0);
    },
  },
};
</script>
